import React from "react";
import styled from "styled-components";
import { colors, spacers, breakpoints } from "../styles/variables";

interface TitleXLProps {
  children: React.ReactNode;
  spacingTop?: boolean;
}

const TitleXLElem = styled.h1`
  color: ${colors.brand};
  hyphens: auto;
  word-wrap: break-word;

  &.spacingTop {
    margin-top: ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
      margin-top: ${spacers.m}px;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
      margin-top: ${spacers.l}px;
    }
  }
`;

const TitleXL: React.FC<TitleXLProps> = ({ children, spacingTop }) => (
  <TitleXLElem className={spacingTop ? "spacingTop" : ""}>
    {children}
  </TitleXLElem>
);

export default TitleXL;
