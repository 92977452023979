import React from "react";
import styled from "styled-components";

import { breakpoints, spacers } from "../styles/variables";

interface ProjekteGridProps {
  children: React.ReactNode;
}

const GridDiv = styled.div`
  > a {
    display: block;
    margin-bottom: ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    > a {
      margin-bottom: 0;
      &:nth-of-type(9) {
        display: none;
      }
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: ${spacers.m}px;
  }

  @media screen AND (min-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${spacers.l}px;
    a:nth-of-type(9) {
      display: block;
    }
  }
`;

const ProjekteGrid: React.FC<ProjekteGridProps> = ({ children }) => (
  <GridDiv>{children}</GridDiv>
);

export default ProjekteGrid;
