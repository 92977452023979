import React from "react";
import styled from "styled-components";
import { spacers } from "../styles/variables";

interface Inhaltstext {
  html: string;
}

const InhaltsDiv = styled.div`
  h1,
  h2,
  h3,
  h4 {
    hyphens: auto;
    word-wrap: break-word;
  }

  p,
  ul,
  ol,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${spacers.s}px;
  }
`;

const InhaltsText: React.FC<Inhaltstext> = ({ html }) => (
  <InhaltsDiv dangerouslySetInnerHTML={{ __html: html }}></InhaltsDiv>
);

export default InhaltsText;
