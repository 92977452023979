import React from "react";
import styled from "styled-components";
import { imgix } from "../styles/variables";
import { heights } from "../styles/variables";
import ImgixImg from "./ImgixImg";

interface PartialPageImgHeader {
  image: string;
}

const ImgHeader = styled.div`
  height: calc(60vh - ${heights.nav}px);
  min-height: 300px;
  width: 100vw;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const PartialPageImgHeader: React.FC<PartialPageImgHeader> = ({ image }) => {
  return (
    <ImgHeader>
      <div>
        <ImgixImg src={image} imgixParams={imgix.partialHeaderImgParams} />
      </div>
    </ImgHeader>
  );
};

export default PartialPageImgHeader;
