import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
//import { Blurhash } from "react-blurhash";
import { Background } from "react-imgix";
import { imgix } from "../styles/variables";

import { colors, spacers } from "../styles/variables";

interface ProjektItemProps {
  itemTitle: string;
  itemImg: string;
  itemLink: string;
}

const ProjektDiv = styled.div`
  /* background: url(${(props) => props.itemImg});
  background-size: cover;
  background-position: center; */
  height: 390px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;

  > div {
    width: 100%;
    height: 100%;
    background-position: center;
  }

  &:hover {
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    > div > div {
      background: ${colors.brand};
    }
  }
`;

const ProjektTitle = styled.div`
  background: ${colors.transparentBg};
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  padding: ${spacers.s}px;
  transition: background 300ms;

  h4 {
    hyphens: auto;
    word-wrap: break-word;
  }
`;

const ProjektItem: React.FC<ProjektItemProps> = ({
  itemTitle,
  itemImg,
  itemLink,
}) => {
  return (
    <Link to={"/" + itemLink}>
      <ProjektDiv itemImg={itemImg}>
        <Background src={itemImg} imgixParams={imgix.gridImgParams}>
          <ProjektTitle>
            <h4>{itemTitle}</h4>
          </ProjektTitle>
        </Background>
      </ProjektDiv>
    </Link>
  );
};

export default ProjektItem;
