import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import ProjekteGrid from "../components/ProjekteGrid";
import ProjektItem from "../components/ProjektItem";
import GridWrapper from "../components/GridWrapper";
import { Helmet } from "react-helmet";

interface FachbereicheQueryProps {
  cms: {
    entry: {
      title: string;
      mainImage: [
        {
          url: string;
        }
      ];
      inhaltstext: string;
      children: [
        {
          title: string;
          uri: string;
          mainImage: [
            {
              url: string;
            }
          ];
        }
      ];
    };
  };
}

const Fachbereiche = () => {
  const data: FachbereicheQueryProps = useStaticQuery(graphql`
    query {
      cms {
        entry(type: "fachbereiche") {
          title
          ... on CMS_inhalt_fachbereiche_Entry {
            mainImage {
              url
            }
            inhaltstext
          }

          children {
            title
            uri
            ... on CMS_inhalt_fachbereich_Entry {
              mainImage {
                url
              }
            }
          }
        }
      }
    }
  `);
  const entry = data.cms.entry;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImage[0].url} />
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <InhaltsText html={entry.inhaltstext} />
      </Wrapper>
      <GridWrapper>
        <ProjekteGrid>
          {entry.children.map((entry) => (
            <ProjektItem
              key={entry.title}
              itemTitle={entry.title}
              itemImg={entry.mainImage[0].url}
              itemLink={entry.uri}
            />
          ))}
        </ProjekteGrid>
      </GridWrapper>
    </DefaultLayout>
  );
};

export default Fachbereiche;
